import React from "react";
import { BaseSection, BaseProps } from "./base";

import { Link } from "gatsby";

/** @jsx jsx */
import { jsx, Grid, Button, Flex } from "theme-ui";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { StaticImage } from "gatsby-plugin-image";

type FeatureCarouselProps = {
  subtitle: string;
  imgSrc: string;
  cta: React.ReactElement;
  children?: React.ReactNode;
};

export const FeatureCarouselSection: React.FC = (
  props: FeatureCarouselProps
) => {
  const carouselRef = React.useRef();

  const bgColor = "white";
  const textColor = "black";

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktopSmall: {
      breakpoint: { max: 1200, min: 800 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const items = props.reviews || [
    {
      description: `Manage your cases, documents, payments, invoices, and more. All in one place, accessed wherever you are.`,
      title: "Case Management",
      imgSrc: "https://media.afterword.com/tr:w-600/site/case-management.png",
      // imgSrc: "/images/product/case-management.png",
    },
    {
      description: `Meet your families where they are without sacrificing your care and personal touch. Guide families through their decisions with videos of your team.`,
      title: "Virtual Funeral Assistant",
      imgSrc:
        "https://media.afterword.com/tr:w-600/site/virtual-funeral-assistant.png",
      // imgSrc: "/images/product/virtual-funeral-assistant.png",
    },
    {
      description: `Increase revenue by curating local sympathy gifts for guests to better support families. From dog walkers, childcare, lawn care, and flowers.`,
      title: "Sympathy Store",
      imgSrc: "https://media.afterword.com/tr:w-600/site/sympathy-store.png",
      // imgSrc: "/images/product/sympathy-store.png",
    },
    {
      description: `Capture all attendance at all of your services, wherever they take place. Using iPads and QR codes, guests can register for a service easily.`,
      title: "Digital Register Book",
      imgSrc: "https://media.afterword.com/tr:w-600/site/register-book.png",
      // imgSrc: "/images/product/register-book.png",
    },
    {
      description: `Connect to the many systems you already use to run your business. Afterword has flexible APIs to integrate all of your existing systems.`,
      title: "Integrations",
      imgSrc: "https://media.afterword.com/tr:w-600/site/integrations2.png",
      // imgSrc: "/images/product/integrations.png",
    },
    {
      description: `Collect secure payments from all major credit cards with one blended rate. Collect them in-person, online, or through email.`,
      title: "Payments & Billing",
      imgSrc: "https://media.afterword.com/tr:w-600/site/billing.png",
      // imgSrc: "/images/product/billing.png",
    },
  ];
  //   shuffleArray(reviews);

  const gridItem = (item) => (
    <div
      sx={{
        borderRadius: "8px",
        backgroundColor: "beige",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        padding: "16px 24px",
        minHeight: "360px",
      }}
    >
      <img
        src={item.imgSrc}
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid #DEDEDE",
        }}
      />
      <p sx={{ fontWeight: 700, fontSize: 2, marginTop: 2 }}>{item.title}</p>
      <p>{item.description}</p>
    </div>
  );

  return (
    <BaseSection
      id={props.id}
      sectionSx={{
        backgroundColor: bgColor,
        color: textColor,
        // fontFamily: "Inter",
      }}
    >
      <div
        className="aw-content"
        sx={{
          "& .carousel-item": {
            // marginRight: "24px",
            // height: "340px",
          },
          "& .react-multi-carousel-list": {
            position: "static",
            overflow: "visible",
          },
        }}
      >
        <h2
          sx={{
            fontWeight: "400",
            fontSize: ["30px", "30px", "48px"],
            marginBottom: 2,
            lineHeight: "120% !important",
            fontFamily: "Georgia",
            // letterSpacing: "0.01em",
          }}
        >
          {props.title}
        </h2>

        <p sx={{ fontSize: "20px", marginBottom: 4 }}>{props.description}</p>

        {props.fullGrid && (
          <Grid columns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]} gap={4}>
            {items.map((item, idx) => {
              return gridItem(item);
            })}
          </Grid>
        )}

        {!props.fullGrid && (
          <Carousel
            swipeable={true}
            draggable={false}
            slidesToSlide={1}
            showDots={false}
            renderDotsOutside
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={false}
            autoPlay={false}
            //   autoPlay={props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={6000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            itemClass="carousel-item"
            ref={carouselRef}
            //   additionalTransfrom={-24}
          >
            {items.map((item, idx) => {
              return (
                <div key={idx} sx={{ marginRight: "24px" }}>
                  {gridItem(item)}
                </div>
              );
            })}
          </Carousel>
        )}
      </div>
    </BaseSection>
  );
};
