import React from "react";
import { graphql, Link } from "gatsby";
import { useState } from "react";

import Layout from "../components/layout-main";
import { IS_BROWSER, scrollToElm } from "../components/dom";
import { ContactForm, FormTypes } from "../components/form-contact";
import CalendlySection from "../components/form-calendly";
import Testimonials from "../components/testimonials";
import { CalendlyEventTypes } from "../components/form-calendly";

/** @jsx jsx */
import { jsx, Grid } from "theme-ui";

import { useBreakpointIndex } from "@theme-ui/match-media";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { FeatureCarouselSection } from "../components/sections/feature-carousel";
import { FuneralHomeTestimonials } from "../constants/testimonials";
import { HowItWorksBase } from "../components/lp/how-it-works";

export const replacementMap = {
  Passare: "/logos/passare.svg",
  Crakn: "/logos/crakn.png",
  SRS: "/logos/srs.png",
  Tukios: "/logos/tukios.png",
  eFuneral: "/logos/efuneral-long.png",
  PartingPro: "/logos/partingpro.png",
  Batesville: "/logos/batesville.png",
  TributeTech: "/logos/tributetech.svg",
  Excel: "/logos/excel.png",
};

const FeaturePanel = (props) => {
  const width = ["100%", 600];

  return (
    <div
      // key={props.keyVal}
      sx={{
        maxWidth: "100%",
        margin: "0 auto",
        marginTop: "48px",
        paddingBottom: ["24px", 0],
        borderBottom: "1px solid",
        borderColor: ["rgba(0, 0, 0, 0.35)", "transparent"],
        textAlign: ["center", "center", "inherit"],
      }}
    >
      <Grid
        gap={["12px", "24px"]}
        columns={["1fr", "1fr", "3fr 1fr"]}
        sx={{
          alignItems: "start",
          display: ["grid"],
        }}
      >
        <div>
          <div>
            <h1
              key={`${props.keyVal}-h1`}
              sx={{
                margin: 0,
                fontWeight: 700,
                fontSize: [4, "36px"],
              }}
            >
              {props.title}
            </h1>
            <h1
              key={`${props.keyVal}-h2`}
              sx={{
                marginTop: 0,
                fontWeight: 700,
                fontSize: props.smallSubtitle ? [4, "22px"] : [4, "36px"],
                lineHeight: [1, 1],
                color: "#a7aec1",
              }}
            >
              {props.subtitle}
            </h1>
          </div>

          <p sx={{ fontSize: [3, "18px"] }}>{props.blurb}</p>

          {props.cta && props.cta}
        </div>

        <div>
          {props.imgRef && (
            <img
              sx={{
                minWidth: "300px",
                maxWidth: width,
                margin: ["0 auto"],
                borderRadius: "8px",
              }}
              src={props.imgRef}
            />
          )}
          {props.vidRef && (
            <div
              sx={{
                margin: ["0 auto", "auto"],
                position: "relative",
                width: ["100%", "600px"],
                maxWidth: width,
                overflow: "hidden",
                pointerEvents: "none",
              }}
            >
              {/* <img
                src={isMobile ? "/images/iphone-12.png" : "/images/mbp.png"}
                sx={{
                  maxWidth: maxWidth,
                  top: 0,
                  position: "absolute",
                  zIndex: 2,
                }}
              /> */}
              <video
                preload
                autoPlay
                playsInline
                webkit-playsinline
                muted
                loop={true}
                poster={props.poster}
                sx={{
                  position: "relative",
                  top: 0,
                  zIndex: 1,
                  // maxWidth: width - 100,
                  maxWidth: width,
                  // padding: isMobile ? "0 12px 4px 12px" : "40px 64px 24px 24px",
                  // borderRadius: "30px",
                  // marginTop: "4px",
                  // marginLeft: isMobile ? 0 : "36px",
                  borderRadius: "8px",
                }}
              >
                <source type="video/mp4" src={props.vidRef} />
              </video>
            </div>
          )}
        </div>
      </Grid>

      <Grid
        columns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
        sx={{ marginTop: "36px" }}
        gap={[1, 4]}
      >
        {(props.callouts || []).map((item) => (
          <div
            key={item.title}
            sx={{
              background: "rgba(255, 255, 255, 0.07)",
              borderRadius: "8px",
              padding: 4,
              textAlign: "center",
            }}
          >
            <h3 sx={{ marginTop: 0 }}>{item.title}</h3>
            {item.description}
          </div>
        ))}
      </Grid>
    </div>
  );
};

export const FeatureCallouts = ({ data, features, laptop }) => {
  const titles = features || [
    {
      title: "Language & Cultural Support",
      tag: "Multiple Languages",
      vidRef: "/images/tool2.mp4",
      blurb:
        "We translate all content, including your bereavement resources, to Spanish, Chinese, and a growing list of other languages. We also offer curated guidance based on cultural and spiritual beliefs.",
    },
    {
      title: "Individualized Grief Resources",
      tag: "Individualized Support",
      vidRef: "/images/tool4.mp4",
      blurb:
        "We've created a database of over 1,000+ resources across the country. Families can find relevant resources for each member based on their relationship, age, and preferences. Combined with your hospital bereavement groups, we personalize the recommendations for each individual because grief is unique.",
    },
    {
      title: "Comprehensive Funeral Guidance",
      tag: "Funeral Guidance",
      vidRef: "/images/tool3.mp4",
      blurb:
        "Through immersive video content, we walk families through their funeral options. Based on what's important to them—religious beliefs, sustainability, personal preferences—we guide them through every step in the process. We offer individualized guidance so they feel confident about their decisions.",
    },
    {
      title: "Automatically Updated",
      tag: "Automatically Updated",
      blurb:
        "We combine our comprehensive resources with your bereavement guide and automatically keep the resources up-to-date. If a funeral home changes their phone number, if a local grief support group updates their schedule, if a bereavement book becomes a National bestseller—we've got you covered.",
      vidRef: "/images/tool1-2.mp4",
    },
    {
      title: "Available Wherever You Are",
      tag: "Easily Accessible",
      blurb:
        "There's no app download needed. Families can use the tool from their phone, tablet, or laptop—any device that's connected to the internet. ",
    },
  ];
  const [panelVisible, setPanelVisible] = useState(true);
  const [value, setValue] = useState(titles[0]);
  const breakpointIndex = useBreakpointIndex();
  const breakpointIndexToFlip = 0;

  return (
    <div>
      {titles?.length > 1 && breakpointIndex > breakpointIndexToFlip && (
        <div
          sx={{
            display: "flex",
            justifyContent: ["space-around", "space-around", "space-around"],
            // border: "2px solid #EEEEEE",
            // borderRadius: "30px",
            flexWrap: "wrap",
            fontWeight: 500,
            gridTemplateColumns: titles.map((_) => "1fr").join(" "),
            borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
          }}
        >
          {titles.map((section, idx) => {
            let isSelected = section.title === value.title;
            return (
              <p
                key={idx}
                sx={{
                  // borderRadius: "30px",
                  // margin: "4px",
                  textAlign: "center",
                  height: "46px",
                  padding: 0,
                  margin: 0,
                  lineHeight: "46px",
                  color: isSelected ? "white" : "rgba(255, 255, 255, 0.65)",
                  // color: isSelected ? "black" : "rgba(48, 48, 49, 0.65)",
                  borderBottom: "2px solid",
                  borderColor: isSelected ? "white" : "transparent",
                  // backgroundColor: isSelected ? "navy" : "inherit",
                  // color: isSelected ? "white" : "inherit",
                  "&:hover": {
                    cursor: "pointer",
                    // textDecoration: !isSelected ? "underline" : "none",
                    color: "white",
                    borderColor: "white",
                  },
                  verticalAlign: "middle",
                }}
                onClick={() => {
                  setValue(section);
                  scrollToElm("feature-callouts");
                }}
              >
                {section.tag}
              </p>
            );
          })}
        </div>
      )}
      {breakpointIndex > breakpointIndexToFlip && (
        <div
          sx={{
            "& .example-enter": {
              opacity: 0.01,
            },
            "& .example-enter.example-enter-active": {
              opacity: 1,
              transition: "opacity 200ms ease-in-out",
            },
            "& .example-exit": {
              opacity: 1,
            },
            "& .example-exit.example-exit-active": {
              opacity: 0.01,
              transition: "opacity 200ms ease-in-out",
            },
          }}
        >
          <SwitchTransition mode={"out-in"}>
            <CSSTransition
              key={value.title + "css"}
              in={panelVisible}
              timeout={300}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="example"
            >
              <FeaturePanel
                key={value.title}
                imgRef={value.imgRef}
                vidRef={value.vidRef}
                poster={value.poster}
                title={value.title}
                cta={value.cta}
                subtitle={value.subtitle}
                smallSubtitle={value.smallSubtitle}
                replaces={value.replaces}
                blurb={value.blurb}
                laptop={laptop}
                callouts={value.callouts}
              />
            </CSSTransition>
          </SwitchTransition>
        </div>
      )}

      {breakpointIndex <= breakpointIndexToFlip && (
        <div>
          {titles.map((section, idx) => {
            return (
              <FeaturePanel
                key={section.title}
                imgRef={section.imgRef}
                vidRef={section.vidRef}
                poster={section.poster}
                title={section.title}
                cta={value.cta}
                subtitle={section.subtitle}
                smallSubtitle={value.smallSubtitle}
                replaces={section.replaces}
                blurb={section.blurb}
                callouts={section.callouts}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const OnlinePlanner = ({ data, location }) => {
  const [caseVolume, setCaseVolume] = React.useState(200);
  const [burialRate, setBurialRate] = React.useState(40);
  const ARPC_BOOST = 0.09;

  const revPotential = React.useMemo(() => {
    return (
      ((caseVolume * burialRate) / 100) * 7000 * ARPC_BOOST +
      ((caseVolume * (100 - burialRate)) / 100) * 5000 * ARPC_BOOST
    );
  }, [caseVolume, burialRate]);

  const hookTarget = IS_BROWSER ? [window.location.hash] : [];

  React.useEffect(() => {
    if (window.location.hash === "#contactus") {
      scrollToElm("calculator", 0);
      setTimeout(() => {
        let elm = document.querySelector("form input[type=text]");
        elm && elm.focus();
      }, 800);
    }
  }, hookTarget);

  return (
    <Layout
      location={location}
      pageTitle="Forget what you know about online planning"
      hideSubscribe
    >
      <section
        id="features"
        sx={{ backgroundColor: "darkBlue", color: "white", paddingTop: "1rem" }}
      >
        <div className="aw-content">
          <FeatureCallouts
            laptop
            features={[
              {
                tag: "Video-based Ecommerce",
                title: "Guide families online",
                subtitle: "As if they walked in",
                replaces: ["eFuneral", "PartingPro", "Passare"],
                vidRef:
                  "https://storage.googleapis.com/afterword-static/product/Ecommerce%20assistant.mp4",
                poster:
                  "https://media.afterword.com/tr:w-600/site/Ecommerce%20assistant.png",
                // poster:
                //   "https://storage.googleapis.com/afterword-static/product/Ecommerce%20assistant.png",

                blurb: `Our online arranging tools make you the leader with educational videos, real-time pricing, high-quality photos, and an ecommerce experience unlike anything out there.`,
                callouts: [
                  {
                    imgSrc: "",
                    title: "Education from experts: you",
                    description: `Your communities are looking for funeral guidance, but they'll likely find inaccurate information online. Educate online families about the importance of funeral services.`,
                  },
                  {
                    imgSrc: "",
                    title: "More than packages",
                    description: `Forget fitting into three packages that cause more questions than answers. There are no limitations to the services families can select online.`,
                  },
                  {
                    imgSrc: "",
                    title: "Burial and Cremation",
                    description: `Online families are looking for more than a discount cremation brand. Offer all of your services in-person and online without decreasing ARPC.`,
                  },
                ],
              },
              {
                tag: "Virtual Selection Room",
                title: "All your inventory",
                subtitle: "Unlimited products",
                replaces: ["Batesville", "TributeTech", "Showroom"],
                vidRef:
                  "https://storage.googleapis.com/afterword-static/product/Selection%20room.mp4",
                // poster:
                //   "https://storage.googleapis.com/afterword-static/product/Selection%20room.png",
                poster:
                  "https://media.afterword.com/tr:w-600/site/Selection%20room.png",
                blurb:
                  "Display all your products & services without needing more physical space. Use your precious real estate to offer more to families.",
                callouts: [
                  {
                    imgSrc: "",
                    title: "Endless merchandise",
                    description: `Offer endless products and merchandise from all of your vendors. Display videos, images, and descriptions.`,
                  },
                  {
                    imgSrc: "",
                    title: "Beautifully shown in-person",
                    description: `Connect to the TV screen in your arrangement room to guide families through all of their options.`,
                  },
                  {
                    imgSrc: "",
                    title: "Never miss an offering",
                    description: `With Afterword, every Funeral Director at your firm can guide families through every product you offer in the order your best director takes them through.`,
                  },
                ],
              },
            ]}
          />
        </div>
      </section>

      <CalendlySection
        pageName="funeral-homes"
        headerOverride="Ready to launch? Schedule time with our team to set you up"
        buttonOverride="Schedule onboarding"
        buttonVariant={"blueButton"}
        calendlyType={CalendlyEventTypes.FUNERAL_HOMES}
      />

      {/* <section
        id="case-studies"
        sx={{
          paddingTop: "3rem",
          paddingBottom: 0,
        }}
      >
        <div className="aw-content">
          <h1
            sx={{
              fontWeight: "400",
              fontSize: ["30px", "36px", "52px"],
              lineHeight: "133% !important",
              letterSpacing: "0.01em",
              fontFamily: "Georgia, serif",
              marginBottom: [2, 0],
            }}
          >
            How it Works
          </h1>

          <p sx={{ fontSize: 3 }}>
            Funeral Homes like yours are benefitting from Afterword across
            nearly every metric. Here how it works:
          </p>
        </div>
      </section> */}

      {/* <HowItWorksBase
        title=""
        items={[
          {
            title: `Set up your account`,
            description: (
              <>
                Use our setup tool or work with an Afterword account manager to
                configure Afterword for you. This can take as short as 15
                minutes.
              </>
            ),
          },
          {
            title: `Add the online planner link to your website`,
            description: `Once your account is activated, you'll have a dedicated Afterword URL, that you then post on your website.`,
          },
          {
            title: `Receive emails when families starts planning & when they finish`,
            description: `You'll receive emails from Afterword whenever a family starts planning and after they complete the planning process.`,
          },
          {
            title: `Login to Afterword to see the full details`,
            description: `You can always login to see their full list of preferences in Afterword, or you can use the email notificaiton and reach out to the family directly.`,
          },
        ]}
      /> */}

      <section
        id="case-studies"
        sx={{
          paddingTop: "3rem",
          paddingBottom: 0,
        }}
      >
        <div className="aw-content">
          <h1
            sx={{
              fontWeight: "400",
              fontSize: ["30px", "36px", "52px"],
              lineHeight: "133% !important",
              letterSpacing: "0.01em",
              fontFamily: "Georgia, serif",
              marginBottom: [2, 0],
            }}
          >
            Metrics that matter
          </h1>
        </div>
      </section>
      <HowItWorksBase
        title=""
        items={[
          {
            title: `Secure 20% of online leads`,
            description: `Afterword's Virtual Funeral Assistant leads to significantly higher conversion rates than the industry standard of 10%. The best interface is your face—your video guidance yields higher conversion and better experiences for families.`,
          },
          {
            title: `Service both cremation and burial families online`,
            description: `It's not just cremation—despite common belief, you can service every type of disposition online. Our early customers are seeing over 30% burial for families planning online.`,
          },
          {
            title: `Consistent ARPC online and in-person`,
            description: `Whether a family comes to you in-person or online, our platform keeps ARPC consistent, with a variance of less than 5%. Meet your
                  families where they are without sacrificing your care and personal
                  touch.`,
          },
        ]}
      />

      <Testimonials
        sectionClasses="aw-accent"
        id="director-testimonials"
        title="What funeral directors are saying"
        reviews={FuneralHomeTestimonials}
      />

      <FeatureCarouselSection
        title="Looking for more than just an Online Planner?"
        description={
          <>
            We also offer a comprehensive solution --{" "}
            <Link href="/platform">learn more here</Link>
          </>
        }
      />

      <section sx={{ backgroundColor: "beige" }} id="calculator">
        <div className="aw-content">
          <Grid
            gap={[3, 6]}
            columns={["1fr", "1fr 2fr 1fr"]}
            sx={{ alignItems: "center" }}
          >
            <div></div>
            <div
              sx={{
                paddingTop: 4,
              }}
            >
              <h2 sx={{ marginTop: 0 }}>Sign up for a full demo</h2>
              <ContactForm
                buttonVariant="blueButton"
                formType={FormTypes.FUNERAL_SAAS}
                formTitle=""
                formOutro="Thanks! Our team will reach out to you shortly."
                hideImage={true}
                buttonText="Sign up"
                calendlyType={CalendlyEventTypes.FUNERAL_HOMES}
                signUpData={`Cases=${caseVolume};BurialRate=${burialRate};Potential=${revPotential}`}
              />
            </div>
            <div></div>
          </Grid>
        </div>
      </section>

      {/* <Faqs
        id="faqs"
        faqsTitle="Frequently Asked Questions"
        variant="accordion"
        hideCalendly
        questions={[
          {
            question:
              "My state or county requires something unique. Does Afterword support it?",
            answer:
              "Absolutely. You can customize the vital collection in Afterword to match your current process.",
          },
          {
            question:
              "What about all my data from the past? Can I import that into your system?",
            answer:
              "Yes. All our plans include full-service data migration from your existing software solutions. We will import all your data and include all data fields.",
          },
          {
            question: "How long does it take to implement?",
            answer:
              "With the video interviews, digitizing your pricebook & merchandise data migration, we usually launch new customers within 3 weeks. This can vary based on the systems you use and your schedules.",
          },
          {
            question: "Can I make changes after I launch? How easy is it?",
            answer:
              "Of course. Everything within our system is customizable and editable. It's so easy that onboarding new users typically takes less than 20 minutes.",
          },
          {
            question: "Is your software compliant with the FTC?",
            answer:
              "Absolutely. Afterword helps you meet and exceed all requirements of the FTC Funeral Rule.",
          },
          // {
          //   question: "How many funeral homes are using Afterword?",
          //   answer: "",
          // },
          {
            question: "Can my families use parts of the software?",
            answer:
              "They can select their preferences, plan online, and enter their important vitals using our Virtual Funeral Assistant",
          },
          {
            question: "Why did you get into the funeral industry?",
            answer: (
              <>
                Our co-founder & CEO, Effie Anolik, started Afterword after
                planning her dad's funeral in August 2018. She was so surprised
                by the process and was dissastifised with her experience. Ever
                since then, Afterword has worked with families & funeral homes
                to improve the planning experience. You can{" "}
                <Link to={Routes.ABOUT}>read more about our story here</Link>.
              </>
            ),
          },
          {
            question: "Where will our data be stored?",
            answer: (
              <>
                Your data are stored and encrypted in the cloud. You can{" "}
                <Link to={Routes.SECURITY}>
                  read more about our security practices here
                </Link>
                .
              </>
            ),
          },
          {
            question: "Do I need to download any apps?",
            answer:
              "No. Our software is web-based and works across all your devices.",
          },
        ]}
      /> */}
    </Layout>
  );
};

export default OnlinePlanner;

export const pageQuery = graphql`
  query {
    service: file(relativePath: { regex: "/app-usage.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hereAccessible: file(relativePath: { regex: "/trophy.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    herePersonalized: file(relativePath: { regex: "/chart-histogram.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    here247: file(relativePath: { regex: "/phone-call.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hereSupport: file(relativePath: { regex: "/following.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
